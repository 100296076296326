import { effect, inject, Injectable, signal } from '@angular/core';
import { ConnectionStatus } from 'app/models/connection_status.enum';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import {
  Configuration,
  MembershipsService,
  Product,
  ProductService,
  UserMembership,
} from 'src/api';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  public userSignal = signal<KeycloakProfile | undefined>(undefined);
  public authStatusSignal = signal(ConnectionStatus.DISCONNECTED);
  private keycloakService = inject(KeycloakService);
  private membershipsService = inject(MembershipsService);

  public configuration = inject(Configuration);

  public settingsService = inject(SettingsService).settingsSignal;

  public membershipSignal = signal<UserMembership | undefined>(undefined);
  productService = inject(ProductService);

  currentUserProductsSignal = signal([] as Product[]);

  constructor() {
    effect(() => {
      this.loadUserProducts();
    });
  }

  public loadUserProducts() {
    if (!this.userSignal()?.id) return;
    this.productService
      .getAllUsersProducts(this.userSignal()?.id ?? '', true)
      .subscribe({
        next: (products) => {
          this.currentUserProductsSignal.set(products.products ?? []);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  async fetchUser() {
    this.authStatusSignal.set(ConnectionStatus.CONNECTING);
    await this.getUserFromKeycloak();
  }

  async logout() {
    await this.keycloakService.logout();
    this.authStatusSignal.set(ConnectionStatus.DISCONNECTED);
  }

  async getUserFromKeycloak() {
    this.configuration.credentials = {
      Authorization: await this.keycloakService.getToken(),
    };
    let keycloakProfile;
    try {
      keycloakProfile = await this.keycloakService.loadUserProfile();
    } catch {
      console.log('User not logged in');
      return;
    }
    this.userSignal.set(keycloakProfile);
    this.authStatusSignal.set(ConnectionStatus.CONNECTED);
    this.getUserMembership();
  }

  getUserMembership() {
    this.membershipsService
      .getTheActiveUsersMembership(this.userSignal()?.id ?? '')
      .subscribe((memberships) => {
        this.membershipSignal.set(memberships);
      });
  }
}
